'use client';

import { useState, useEffect } from 'react';
import { marked } from 'marked';

function simulateStreamedResponse(
  text: string,
  setCompletion: (value: string) => void
) {
  const markdown = marked.parse(text).toString();

  const words = markdown.split(' ');
  let currentText = '';

  words.forEach((word, index) => {
    setTimeout(() => {
      currentText += word + ' ';
      setCompletion(currentText);
    }, index * 50);
  });
}

export default function Welcome() {
  const [completion, setCompletion] = useState<string>('');

  useEffect(() => {
    const browserLanguage =
      navigator.language || (navigator.languages && navigator.languages[0]);

    const fetchCompletion = async () => {
      const response = await fetch('/api/welcome', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ lang: browserLanguage }),
      });
      const data = await response.json();
      simulateStreamedResponse(data.completion, setCompletion);
    };

    fetchCompletion();
  }, []);

  return (
    <div className="prose dark:prose-invert">
      {completion ? (
        <div dangerouslySetInnerHTML={{ __html: completion }} />
      ) : (
        <p>Loading welcome message...</p>
      )}
    </div>
  );
}
